import React from "react";
import webProjects from "../webProjects";
import designProjects from "../designProjects";
import InlineSVG from "svg-inline-react";
import ScrollAnimation from "react-animate-on-scroll";
const link = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M476.85,35.15a120,120,0,0,0-169.7,0L206.85,135.44c-48.47,48.48-44,125.72,0,169.71A114.53,114.53,0,0,0,231,323.45l18.31-18.3c12-12,7.78-26,7.56-36.18a57.5,57.5,0,0,1-7.56-6.25c-22.57-22.57-23.59-61.26,0-84.85L349.57,77.58a60,60,0,0,1,84.85,84.85l-66.29,66.29c1.92,10.61,13.42,35.73,7.51,77.18.28-.28.63-.46.92-.75l100.29-100.3A120,120,0,0,0,476.85,35.15Z"/><path d="M312.92,199.08a114.53,114.53,0,0,0-24.12-18.3l-18.31,18.3c-12,12-7.78,26-7.56,36.17a57.72,57.72,0,0,1,7.56,6.26c22.58,22.57,23.59,61.26,0,84.85L162.43,434.43a60,60,0,0,1-84.86-84.86l74.07-74.06c-1.92-10.61-13.42-35.74-7.5-77.19-.29.29-.64.47-.93.76L35.15,307.15a120,120,0,0,0,169.7,169.7L312.92,368.79C360.49,321.21,357.89,244.06,312.92,199.08Z"/></svg>`

const Projects = () => {

    return (
        <div className="tpl__projects" id="projects">
            <div className="tpl">
                <div className="container-medium">
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-6 grid-l-offset-2">
                            <ScrollAnimation animateIn="slideInLeft">
                                <div className="comp__heading">
                                    <h2 className="heading heading--yellow">Projects</h2>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        {webProjects.reverse().map((project, i) =>
                            project.size === 'big' ?
                                <div className="grid-xs-22 grid-xs-offset-1 grid-s-17 grid-s-offset-6 grid-xl-17 grid-xl-offset-7 item item--bigger" key={i}>
                                    {/*<video autoPlay={true} loop={true} playsInline>*/}
                                    {/*    <source src={`${require("../video/" + project.img + ".mp4")}`}/>*/}
                                    {/*</video>*/}
                                    <div className="bg" style={{backgroundImage: `url(${require("../img/"+project.img+".png")})`}} />
                                    <div className="wrapper">
                                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                                            <span className="period period--bigger">{project.period}</span>
                                            <h3 className="title title--bigger">{project.name}</h3>
                                            <div className="subtitle subtitle--bigger">{project.desc}</div>
                                            <a href={project.link} target="_blank" rel="noopener noreferrer" className="btn btn--lightBlack"><InlineSVG src={link} /></a>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            :
                                <div className="grid-xs-18 grid-xs-offset-3 grid-s-12 grid-s-offset-8 grid-l-7 grid-l-offset-4 item" key={i}>
                                    {/*<video autoPlay={true} loop={true} playsInline>*/}
                                    {/*    <source src={`${require("../video/"+project.img+".mp4")}`} />*/}
                                    {/*</video>*/}
                                    <div className="bg" style={{backgroundImage: `url(${require("../img/"+project.img+".png")})`}} />
                                    <div className="wrapper">
                                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                                            <span className="period">{project.period}</span>
                                            <h4 className="title">{project.name}</h4>
                                            <div className="subtitle">{project.desc}</div>
                                            <a href={project.link} target="_blank" rel="noopener noreferrer" className="btn btn--lightBlack"><InlineSVG src={link} /></a>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                        )}
                        {designProjects.reverse().map((project, i) =>
                            project.size === 'big' ?
                                <div className="grid-xs-22 grid-xs-offset-1 grid-s-17 grid-s-offset-6 grid-xl-17 grid-xl-offset-7 item item--bigger" key={i}>
                                    <div className="bg" style={{backgroundImage: `url(${require("../img/"+project.img+".png")})`}} />
                                    <div className="wrapper">
                                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                                            <span className="period period--bigger">{project.period}</span>
                                            <h3 className="title title--bigger">{project.name}</h3>
                                            <div className="subtitle subtitle--bigger">{project.desc}</div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                :
                                <div className="grid-xs-18 grid-xs-offset-3 grid-s-12 grid-s-offset-8 grid-l-7 grid-l-offset-4 item" key={i}>
                                    <div className="bg" style={{backgroundImage: `url(${require("../img/"+project.img+".png")})`}} />
                                    <div className="wrapper">
                                        <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                                            <span className="period">{project.period}</span>
                                            <h4 className="title">{project.name}</h4>
                                            <div className="subtitle">{project.desc}</div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="moreItems">
                            <ScrollAnimation animateIn="fadeIn" duration={2} animateOnce={true}>
                                <span>and much much more...</span>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="container-medium comp__cta">
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-7 grid-l-offset-2">
                            <ScrollAnimation animateIn="slideInLeft">
                                <div className="comp__heading">
                                    <h2 className="heading heading--yellow">Do you like my projects?</h2>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-14 grid-l-offset-2">
                            <div className="content">
                                <ScrollAnimation animateIn="fadeIn" duration={2}>
                                    <p>... or would you like a modern website based on the latest technologies? Feel free to write me a message.</p>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInUp">
                                    <a href="mailto:info@patrikpalko.com" className="btn btn--lightBlack">Contact me</a>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects;