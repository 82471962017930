import React from "react";
import bg from '../img/patko.png'
import InlineSVG from 'svg-inline-react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import ScrollAnimation from "react-animate-on-scroll";
const li = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g id="Bold"><path d="M511.87,512v0H512V324.2c0-91.86-19.78-162.62-127.17-162.62-51.62,0-86.27,28.33-100.41,55.19h-1.5V170.15H181.1V512h106V342.72c0-44.57,8.44-87.66,63.63-87.66,54.38,0,55.19,50.86,55.19,90.52V512Z"/><path d="M8.45,170.18H114.6V512H8.45Z"/><path d="M61.48,0A61.5,61.5,0,0,0,0,61.48c0,33.94,27.54,62.06,61.48,62.06S123,95.42,123,61.48A61.54,61.54,0,0,0,61.48,0Z"/></g></svg>`;
const ig = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512.01"><g id="Bold"><path d="M256.09,124.53A131.37,131.37,0,1,0,387.46,255.9,131.36,131.36,0,0,0,256.09,124.53Zm0,216.64a85.27,85.27,0,1,1,85.27-85.27A85.24,85.24,0,0,1,256.09,341.17Z"/><path d="M361.56,1.61c-47.1-2.2-163.77-2.09-210.92,0C109.21,3.55,72.67,13.55,43.2,43-6,92.25.26,158.6.26,255.9c0,99.59-5.55,164.4,42.94,212.89,49.43,49.4,116.74,42.94,212.89,42.94,98.65,0,132.69.06,167.57-13.44,47.43-18.41,83.22-60.8,86.72-136.94,2.22-47.12,2.09-163.77,0-210.92C506.16,60.55,457.92,6.05,361.56,1.61Zm74.56,434.6c-32.28,32.28-77.05,29.4-180.65,29.4-106.67,0-149.44,1.58-180.65-29.72-35.95-35.78-29.44-93.23-29.44-180.33,0-117.87-12.1-202.75,106.2-208.81,27.18-1,35.18-1.28,103.59-1.28l1,.64c113.69,0,202.88-11.9,208.24,106.37,1.21,27,1.49,35.09,1.49,103.4,0,105.43,2,148.46-29.74,180.33Z"/><circle cx="392.67" cy="119.35" r="30.7"/></g></svg>`;
const mail = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.99 426.67"><g id="Bold"><path d="M200.9,281.2l-8.47,119.13c12.12,0,17.37-5.21,23.66-11.46l56.81-54.29,117.72,86.21c21.59,12,36.8,5.69,42.62-19.86L510.51,38.86l0,0C517.38,6.92,499-5.56,478,2.27L23.77,176.16c-31,12-30.53,29.31-5.27,37.14l116.12,36.12L404.33,80.65c12.7-8.41,24.24-3.76,14.75,4.65Z"/></g></svg>`;
const before = `<svg class="before" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.28 89.85"><polygon points="0 16.19 25.28 0 25.28 89.85 0 74.05 0 16.19" style="fill-rule:evenodd"/></svg>`;


const Header = () => {
        return (
            <div className="tpl__mainHeader" id="home">
                <div className="tpl">
                    <div className="container-fluid">
                        <div className="socialIcons">
                            <a href="https://www.linkedin.com/in/patrik-palko/" className="social" target="_blank" rel="noopener noreferrer">
                                <ScrollAnimation animateIn="fadeIn" duration={4} animateOnce={true}>
                                    <InlineSVG src={li} />
                                </ScrollAnimation>
                            </a>
                            <a href="https://www.instagram.com/patricio_palko/" className="social" target="_blank" rel="noopener noreferrer">
                                <ScrollAnimation animateIn="fadeIn" duration={4} animateOnce={true} delay={500}>
                                    <InlineSVG src={ig} />
                                </ScrollAnimation>
                            </a>
                            <a href="mailto:info@patrikpalko.com" className="social" target="_blank" rel="noopener noreferrer">
                                <ScrollAnimation animateIn="fadeIn" duration={4} animateOnce={true} delay={1000}>
                                    <InlineSVG src={mail} />
                                </ScrollAnimation>
                            </a>
                        </div>
                        <ScrollAnimation className="scroll" animateIn="fadeIn" duration={4} animateOnce={true} delay={1000}>
                            <span className="scroll__arrow">
                              <span />
                              <span />
                            </span>
                            <span className="scroll__line" />
                            <span className="scroll__text">About me</span>
                        </ScrollAnimation>
                        <div className="row">
                            <div className="grid-xs-24">
                                <div className="content">
                                    <div className="photo" style={{backgroundImage: `url(${bg})`}} />
                                    <div className="title">
                                        <ScrollAnimation animateIn="flipInY" duration={1.5} animateOnce={true} delay={500}>
                                        <InlineSVG src={before} />
                                        </ScrollAnimation>
                                        <h1>Patrik Palko</h1>
                                        <div className="mainTitle">
                                            <ScrollAnimation animateIn="fadeIn" duration={3} animateOnce={true}>
                                                <span className="letter">P</span>
                                                <span className="letter">a</span>
                                                <span className="letter">t</span>
                                                <span className="letter">r</span>
                                                <span className="letter">i</span>
                                                <span className="letter">k</span>
                                                <span> </span>
                                                <span className="letter">P</span>
                                                <span className="letter">a</span>
                                                <span className="letter">l</span>
                                                <span className="letter">k</span>
                                                <span className="letter">o</span>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                    <div className="nameWrapper">
                                        <ParallaxProvider>
                                            <Parallax x={[40, -20]}>
                                                <div className="name">Web developer</div>
                                            </Parallax>
                                        </ParallaxProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


export default Header;