import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { Navbar,Nav} from 'react-bootstrap'
import Home from './Home';
import InlineSVG from "svg-inline-react";
import Scrollspy from 'react-scrollspy';
import NotFoundPage from "./pages/NotFoundPage";

const logo = `<svg class="logo" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.63 362.32"><polygon points="241.63 71.47 135.53 0 0 0 241.63 162.79 241.63 71.47" style="fill:#f9f9f9;fill-rule:evenodd"/><polygon points="19.93 149.61 94.83 99.61 94.83 362.32 19.93 312.02 19.93 149.61" style="fill:#f9f9f9;fill-rule:evenodd"/><polygon points="241.63 162.79 19.93 312.02 19.93 220.83 241.63 71.47 241.63 162.79" style="fill:#f9f9f9;fill-rule:evenodd"/></svg>`;
const before = `<svg class="before" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.28 89.85"><polygon points="0 16.19 25.28 0 25.28 89.85 0 74.05 0 16.19" style="fill-rule:evenodd"/></svg>`;

class BootstrapNavbar extends React.Component{
    render(){
        function handleClick() {
            document.getElementsByClassName("navbar-collapse")[0].classList.remove("show");
            console.log('The link was clicked.');
        }
        return(
            <Router>
                <Navbar expand="lg" sticky="top" collapseOnSelect className="nav-wrap mobile">
                    <div className="navWrapper">
                        <div className="brand">
                            <Navbar.Brand href="/">
                                <InlineSVG src={logo} />
                            </Navbar.Brand>
                        </div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav">MENU</Navbar.Toggle>
                    </div>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Scrollspy items={ [ 'home', 'about', 'skills', 'projects', 'contact'] } currentClassName="active" componentTag="div" className="navbar-nav" offset={-1}>
                            <Nav.Link className="nav-item" href="/" ><InlineSVG src={before} /><span>Home</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/#about" onClick={handleClick}><InlineSVG src={before} /><span>About</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/#skills"><InlineSVG src={before} /><span>Skills</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/#projects"><InlineSVG src={before} /><span>Projects</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/#contact"><InlineSVG src={before} /><span>Contact</span></Nav.Link>
                        </Scrollspy>
                    </Navbar.Collapse>
                </Navbar>
                <Navbar className="nav-wrap main">
                    <div className="navWrapper">
                        <div className="brand">
                            <Navbar.Brand href="/">
                                <InlineSVG src={logo} />
                            </Navbar.Brand>
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Scrollspy items={ [ 'home', 'about', 'skills', 'projects', 'contact'] } currentClassName="active" componentTag="div" className="navbar-nav" offset={-1}>
                                <Nav.Link className="nav-item" href="/"><InlineSVG src={before} /><span>Home</span></Nav.Link>
                                <Nav.Link className="nav-item" href="/#about"><InlineSVG src={before} /><span>About</span></Nav.Link>
                                <Nav.Link className="nav-item" href="/#skills"><InlineSVG src={before} /><span>Skills</span></Nav.Link>
                                <Nav.Link className="nav-item" href="/#projects"><InlineSVG src={before} /><span>Projects</span></Nav.Link>
                                <Nav.Link className="nav-item" href="/#contact"><InlineSVG src={before} /><span>Contact</span></Nav.Link>
                            </Scrollspy>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/404" component={NotFoundPage} />
                    <Redirect to="/404" />
                </Switch>
            </Router>
        )
    }
}

export default BootstrapNavbar;