const skills = [
    {
        "name": "HTML5",
        "progress": "90"
    },
    {
        "name": "CSS3, LESS",
        "progress": "85"
    },
    {
        "name": "Ant design",
        "progress": "55"
    },
    {
        "name": "Redux",
        "progress": "25"
    },
    {
        "name": "SQL",
        "progress": "20"
    },
    {
        "name": "Tailwind CSS",
        "progress": "55"
    },
    {
        "name": "Latte",
        "progress": "40"
    },
    {
        "name": "React",
        "progress": "35"
    },
    {
        "name": "MailChimp",
        "progress": "75"
    },
    {
        "name": "Adobe Photoshop",
        "progress": "60"
    },
    {
        "name": "Adobe Illustrator",
        "progress": "55"
    },
    {
        "name": "Adobe InDesign",
        "progress": "50"
    },

];

export default skills