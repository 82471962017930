import React from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

function About() {

    return (
        <div className="tpl__textBlock" id="about">
            <div className="tpl">
                <div className="container-medium">
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-6 grid-l-offset-2">
                            <ScrollAnimation animateIn="slideInLeft">
                                <div className="comp__heading">
                                    <h2 className="heading heading--yellow">About me</h2>
                                </div>
                            </ScrollAnimation>
                        </div>
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-12 grid-l-offset-2">
                            <ScrollAnimation animateIn='slideInRight'>
                                <p>I am a creative web developer with an overlap in the field of graphic design. I always try to bring simplicity, modern and eye-pleasing elements to every project ... and it’s not just in the digital world - my great passion is also cooking.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                    {/*<div id="nette-debug"></div>*/}
                </div>
            </div>
        </div>
    );
}

export default About;