import React from "react";
import skills from "../skills";
import ProgressBar from "react-bootstrap/cjs/ProgressBar";
import ScrollAnimation from 'react-animate-on-scroll';

const Skills = () => {
    return (
        <div className="tpl__skills" id="skills">
            <div className="tpl">
                <div className="container-medium">
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-6 grid-l-offset-2">
                            <ScrollAnimation animateIn="slideInLeft">
                                <div className="comp__heading">
                                    <h2 className="heading heading--darkPetrol">Skills</h2>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <ScrollAnimation animateIn="fadeIn" duration={2}>
                        <div className="row">
                            <div className="grid-xs-18 grid-xs-offset-3 grid-l-20 grid-l-offset-2">
                                <div className="items">
                                    {skills.map((skill, i) =>
                                        <div className="item" key={i}>
                                            <div className="title">{skill.name}</div>
                                            <ProgressBar now={skill.progress} className="progress--transparent" />
                                            <ProgressBar now={skill.progress} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-6 grid-l-offset-2">
                            <ScrollAnimation animateIn="slideInLeft">
                                <div className="comp__heading">
                                    <h2 className="heading heading--darkPetrol">Others</h2>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-20 grid-l-offset-2">
                            <div className="items items--row">
                                <ScrollAnimation animateIn="fadeIn" duration={2}>
                                    <p>Git, Bitbucket, JIRA, Scrum, CMS, npm, terminal, Gulp, BEM, Browserstack, WordPress</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-6 grid-l-offset-2">
                            <ScrollAnimation animateIn="slideInLeft">
                                <div className="comp__heading">
                                    <h2 className="heading heading--darkPetrol">Self study</h2>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3 grid-l-20 grid-l-offset-2">
                            <div className="items items--row">
                                <ScrollAnimation animateIn="fadeIn" duration={2}>
                                    <p>JS, React, Redux, Typescript, AJAX, JSON, API, UX/UI, SQL, MongoDB, MERN stack, Jenkins, JSX, Netlify, Formik, Yup, SCSS, Adobe Photoshop, Adobe Illustrator, Adobe InDesign, webdesign, graphic design</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;