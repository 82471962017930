const webProjects = [
    {
        "name": "Shipyard",
        "desc": "website",
        "link": "https://shipyard.sk/",
        "period": "10/2017 - 03/2018",
        "img": "shipyard",
        "size": "small"
    },
    {
        "name": "Slovácka hospoda",
        "desc": "website redesign",
        "link": "https://www.slovackahospoda.cz/",
        "period": "03/2019 - 05/2019",
        "img": "hospoda",
        "size": "small"
    },
    {
        "name": "3Kurátorky",
        "desc": "website",
        "link": "https://www.3kuratorky.cz/",
        "period": "03/2019 - 05/2019",
        "img": "3kuratorky",
        "size": "small"
    },
    {
        "name": "Salón Chantall",
        "desc": "website",
        "link": "https://www.salonchantall.sk/",
        "period": "02/2020 - 04/2020",
        "img": "chantall",
        "size": "big"
    },

];

export default webProjects