import React from 'react';
import './less/index.less';
import BootstrapNavbar from './Nav'
import CookieConsent from "react-cookie-consent";
import Loader from "./components/Loader";
import ScrollAnimation from 'react-animate-on-scroll';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-188091380-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
    render() {
        document.title = 'Patrik Palko 💻 portfolio';
        return (
            <div className="page-wrap">
                <BootstrapNavbar />
                <CookieConsent
                    enableDeclineButton
                    // debug={true}
                    declineButtonClasses="btn btn--lightBlack btn--cookie btn--cookie btn--decline"
                    buttonClasses="btn btn--lightBlack btn--cookie"
                    disableButtonStyles="true"
                    contentClasses="content"
                    style={{ background: "#1C1D21" }}
                >
                    This website uses cookies to enhance your experience.
                </CookieConsent>
            </div>
        )
    }
}

export default App