import React from 'react';
import Header from "./components/Header";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import './less/index.less';
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function Home() {
    return (
        <div>
            <Header/>
            <About/>
            <Skills/>
            <Projects/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Home;