import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from "react-animate-on-scroll";

class NotFoundPage extends React.Component{
    render(){
        return (
            <div className="tpl__notFoundPage">
                <div className="tpl">
                    <div className="container">
                        <div className="row">
                            <div className="grid-xs-18 grid-xs-offset-3">
                                <div className="content">
                                    <h1 className="title">404</h1>
                                    <p>Um, something went wrong. Return to the main page and try again.</p>
                                    <Link to="/" className="btn btn--lightBlack">Go back</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default NotFoundPage;