const designProjects = [
    {
        "name": "Bar U nás",
        "desc": "logo",
        "period": "09/2021",
        "img": "denisapodpivniky",
        "size": "small"
    },
    {
        "name": "Bar U nás",
        "desc": "menu",
        "period": "09/2021",
        "img": "denisamenu",
        "size": "small"
    },
    {
        "name": "Vavrincov informačný občasník",
        "desc": "local newspaper",
        "period": "04/2016 - 05/2019",
        "img": "vio",
        "size": "big"
    },
    {
        "name": "Lorinčík",
        "desc": "letterhead paper",
        "period": "01/2019",
        "img": "hlavickovy2",
        "size": "small"
    },
    {
        "name": "Beauty & YEAST",
        "desc": "graphic design of magazine",
        "period": "09/2019",
        "img": "beauty",
        "size": "small"
    },
    {
        "name": "Graduation photographs",
        "desc": "graphic design",
        "period": "11/2020",
        "img": "tablo",
        "size": "big"
    },
    {
        "name": "Notice of graduation",
        "desc": "graphic design",
        "period": "05/2020",
        "img": "oznamko",
        "size": "small"
    },
];

export default designProjects